<template>
  <div class="home">
    <div id="headerjumbo" class="container primary">
      <div id="titlecontainer" class="container">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div id="maintitle">
              <h1 id="headline" class="display-2" style="font-family: 'Playfair Display', serif; color: #000066;">Coaching &amp; Science</h1>
              <h4 id="subtitle" class="display-10" style="color: #000066; font-weight: 400;">Leverage scientific results to tap into your full potential.</h4>
              <h4 id="subtitle" class="display-10" style="color: #000066; font-weight: 400;">Drive positive change. Fulfill your dreams.</h4>
              
              <div style="max-width:500px;margin-top:30px;">
                <label for="signup">Subscribe to  Monika's newsletter</label>
                <div class="input-group mb-1">
                
                  <input id="signup" v-model="email" type="text" class="form-control" placeholder="Your email address" aria-label="Your email." aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#newsletteroptin" type="button" @click="emailupdate">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-12" id="none">
            <img class="img-fluid" src="../assets/img/Hintergrund_6.png">
          </div>
        </div>
      </div>
    </div>

    <div id="description" class="container-fluid" style="margin-top: 0px; padding-top: 200px; padding-bottom: 200px; background-color:#fff;">
      <div class="container" style="text-align: center;">
        <h4 id="subtitle" class="display-8" style="font-family: 'Playfair Display', serif; color: #000066;">Drive positive change. Fulfill your dreams.</h4>
        <div class="row justify-content-center">
          <div class="col-xl-8" style="text-align: left; margin-top: 50px;">
            <p style="font-size: 20px;">Scientific studies are full of great insights, but it is not always clear how to apply them in your real life. That's where I come in. My mission is to energize people in finding their best physical health, personal wellbeing and professional success by leveraging the latest scientific results for their own good.</p>
          </div>
        </div>
      </div>
    </div>

    
    <div id="mindsetboost" class="container-fluid" style="margin-top: 0px; padding-top: 0px; padding-bottom: 0px; background-color:#fff;">
    
      <div class="row">
        <div class="d-none d-md-block col-md-4" style="margin-top: 0px; margin-bottom: 0px; padding-left: 0px; padding-right: 75px;">
          <img src="../assets/img/Mindset_Booster_Foto.png" style="width: 100%; height: 100%; object-fit: fill;">
        </div>
        <div class="col-xl-5 position-relative" style="text-align: left; margin-top: 50px; padding-top: 100px; padding-bottom: 50px;">
          <h4 id="subtitle" class="display-8" style="font-family: 'Playfair Display', serif; color: #000066;">Get the Mindset Booster.</h4>
          <p style="font-size: 20px; padding-top:30px;">Your mindset is the main factor for your success. If you are stuck with a fixed mindset, challenges seem harder to you and you can't perform at 100%. This Mindset Booster helps you to leverage your full potential.</p>
          <button class="btn" style="background-color: #feb2a7;" @click="$router.push('/apps/boost')" type="button">Get the Boost</button>
          <div style="padding-top: 50px;">
            
            <div class="row">
              <div class="col-md" style="padding-top: 50px;">
                <h5 style="font-family: 'Playfair Display', serif; color: #000066;">Get the free ebook</h5>
                <p style="font-size: 20px; width: 80%;">Download the free ebook to have your Mindset Booster always with you!</p>
                <button class="btn" style="background-color: #feb2a7;" data-bs-toggle="modal" data-bs-target="#ebookmodal" type="button">Get the Ebook</button>              
              </div>
              <div class="col" style=" padding-top: 20px;">
                <img style="max-height: 40vh; border:1px solid black;" src="../assets/img/mindsetpreview.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--
    

    <div id="mindsetboost" class="container-fluid" style="margin-top: 0px; padding-top: 200px; padding-bottom: 200px; background-color:#fff;">
      <div class="container" style="text-align: center;">
        <h4 id="subtitle" class="display-8" style="font-family: 'Playfair Display', serif; color: #000066;">Get the Mindset Booster.</h4>
        <div class="row justify-content-center">
          <div class="col-xl-6" style="text-align: left; margin-top: 50px;">
            <h5 style="font-family: 'Playfair Display', serif; color: #000066;">Take the online booster</h5>
            <p style="font-size: 20px; width: 80%;">Your mindset is the main factor for your success. If you are stuck with a fixed mindset, challenges seem harder to you and you can't perform at 100%. This Mindset Booster helps you to leverage your full potential.</p>
            <button class="btn btn-secondary" @click="$router.push('/apps/boost')" type="button">Get the Boost</button>
          </div>
          <div class="col-xl-6" style="text-align: left; margin-top: 50px;">
            <h5 style="font-family: 'Playfair Display', serif; color: #000066;">Get the free ebook</h5>
            <div class="row">
              <div class="col">
                <p style="font-size: 20px; width: 80%;">Download the free ebook to have your Mindset Booster always with you!</p>
                <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#ebookmodal" type="button">Get the Ebook</button>              
              </div>
              <div class="col">
                <img style="max-height: 40vh;" src="../assets/img/mindsetpreview.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
    <!-- Modal Ebook -->
    <div class="modal fade" id="ebookmodal" tabindex="-1" aria-labelledby="imprintmodal" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3> Get the free ebook </h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="color: black; margin-bottom: 20px;">
              <form>
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">Please enter your email address</label>
                  <input type="email" class="form-control" id="ebookemail" v-model="ebookemail" aria-describedby="emailHelp">
                  <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                </div>
               
                <div class="mb-3 form-check">
                  <input type="checkbox" class="form-check-input" v-model="ebookconsentcheck" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">I agree that my personal email is stored. I also agree to receive occasional marketing information. My consent can be revoked at any time.</label>
                </div>
                
              </form>
  
            </div>

            <div>
              <button class="btn btn-primary" :class="{ 'disabled': !ebookconsentcheck }" @click="getebook" data-bs-dismiss="modal" type="button" style="margin-right: 5px;">Get</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="bloghome" class="container-fluid" style="padding-top: 50px; padding-bottom: 100px; background-color:#ced3d7;">

      <div class="container">
        <div class="container" style="margin-top: 50px; text-align: center; background: none;">
          <h4 id="subtitle" class="display-8" style="font-family: 'Playfair Display', serif; color: #000066;">Blog</h4>     
        </div>

        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
          <div class="carousel-inner">
            <div v-for="(item, i) in postmetalist" :key="item" class="carousel-item" :class="{ 'active': i === 0 }">
              <div id="cardcontainer" class="container" style="margin-top: 50px; background: none;">
                <div class="row row-cols-1 row-cols-lg-3 g-4">
                  <div v-for="post in item" :key="post.title" class="col">
                    <div class="card h-100 blogcard">
                      <img :src="post.image" class="card-img-top" alt="..." style="width: 100%; height: 100%;">
                      <div class="card-body">
                        <h5 class="card-title" style="font-family: 'Playfair Display', serif;"> {{post.title}}</h5>
                        <span v-for="tag in post.tags" :key="tag.id" class="badge bg-info text-dark" style="margin-right: 5px;">{{tag}}</span>
                        <router-link :to="{ name: 'blog', params: {id: post.id}}" class="stretched-link"></router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        <div class="container" style="margin-top: 50px; text-align: center; background: none;">
          <div id="bloglink" class="blogcard">
            
            <router-link :to="{ name: 'blogposts'}"><h5 class="card-title" style="font-family: 'Playfair Display', serif;"> Show all posts</h5></router-link>     
          </div>
        </div>
      </div>
    </div>

    <div class="container" id="quote" style="padding-top: 200px; padding-bottom: 200px; min-height: 200px; text-align: center;">
      <h4 id="" class="display-8" style="font-family: 'Playfair Display', serif;">Look deep into Nature and you will understand everything better.</h4>
        <p>- Albert Einstein</p>
    </div>

    <div id="about" class="container-fluid" style="padding-top: 50px; padding-bottom: 100px; background-color:#fddbd3;">

      <div class="container" style="margin-top: 50px; text-align: center; background: none;">
        <h4 id="subtitle" class="display-8" style="font-family: 'Playfair Display', serif; color: #000066;">Hello, I'm Monika</h4>
        <p style="font-size: 20px;">As a trained pharmacist, applying scientific results is at the core of my work. My mission is to make the abstract world of science accessible to all and build a new way of performance coaching on it. I'm currently based in Munich, Germany.</p>
      </div>

    </div>

  </div>
</template>

<script setup>
import axios from 'axios'
import {ref, onMounted, defineEmits} from 'vue'

const posts = ref([])
const postmetalist = ref([])
const email = ref('')

const ebookconsentcheck = ref(false)
const ebooktoken = ref('')
const ebookemail = ref('')

const emit = defineEmits(['emailupdated'])

onMounted(() => {
    console.log("import axios from 'axios'")
    axios.get('https://coachspaceapp-1-u3686118.deta.app/posts').then( res => {
    //axios.get('posts.json').then( res => {
      console.log(res.data)
      posts.value = res.data.slice(0, 3)
      for (var i=0; i<res.data.length; i+=3) {
        var next = Math.min(i+3, res.data.length)
        postmetalist.value.push(res.data.slice(i, next))
      }
    })
  }
)

function getebook() {
  axios.get('https://coachspaceapp-1-u3686118.deta.app/ebooktoken', { params: { email: ebookemail.value } }).then( res => {
    console.log(res.data)
    ebooktoken.value = res.data.token
    let ebookurl = 'https://coachspaceapp-1-u3686118.deta.app/mindsetboost?token=' + res.data.token
    window.location = ebookurl
  })
}

function emailupdate() {
  emit('emailupdated', email.value)
  email.value = ''
}
</script>

<style scoped>

  #headerjumbo {
    background-image: url('../assets/img/Hintergrund_5.jpg');
    
    background-size: auto 100%;
    /*
    background-position:  right 20%;
    */
    background-repeat: no-repeat;
  }

  #description {
    background-image: url('../assets/img/pastell.png');
    
    background-size: cover;
    
    background-position:  50% 50%;
    
    background-repeat: no-repeat;
  }

  #quote {
    background-image: url('../assets/img/Hintergrund_5.jpg');
    
    background-size: cover;
    
    background-position:  50% 50%;
    
    background-repeat: no-repeat;
  }

  #maintitle {
    margin-top: 50px;
    padding-bottom: 50px;
  }

  .card-img-top {
    width: 100%;
    height: auto !important;
    object-fit: cover;
  }

  #headline, #subtitle {
    color: #04224c;
  }
  /*
  #titlecontainer {
      position: relative; 
      top: 10%; 
      left:0%;
    }

  
  @media (min-width: 576px) {
    #titlecontainer {
      position: relative; 
      top: 10%; 
      left:0%;
    }
  }
  */

  .blogcard:hover {
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
  }

  #mobilepicture {
      height: 30vh;
      background-image: url('../assets/img/Website_03.jpg');
      background-size: auto 100%;
      background-position:  right 20%;
      background-repeat: no-repeat;
    }

  @media (min-width: 992px) {
    #headline, #subtitle {
      color: #04224c;
    }
    #jumbotron {
      background-image: url('../assets/img/Website_03.jpg');
    }
    #mobilepicture {
      height: 0vh;
    }

    #maintitle {
      margin-top: 300px;
    }

    .carousel-control-prev {
      transform: translate(-110%, -50%);
    }
    .carousel-control-next {
      transform: translate(110%, -50%);
    }
  }

  .carousel-control-prev {
    background-color: #04224c;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
  .carousel-control-next {
    background-color: #04224c;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

</style>
