<template>
  <div class="container-fluid" style="padding-top: 200px; padding-bottom: 200px; min-height: 200px; margin-top: 0px;" id="headline">
    <div class="row">
      
      <div class="col-0 col-md-2 col-lg-3"></div>

      <div class="col-12 col-md-8 col-lg-6">

        <h1 style="font-family: 'Playfair Display', serif;"> The Science Coach Blog</h1>

      </div>

      <div class="col-0 col-md-2 col-lg-3"></div>

    </div>
  </div>

  <div id="blog" class="container-fluid" style="padding-top: 50px; padding-bottom: 100px; background-color:#ced3d7;">
    <!--
    <div class="container" style="margin-top: 50px; text-align: center; background: none;">
      <h4 id="subtitle" class="display-8" style="font-family: 'Playfair Display', serif; color: #000066;">Blog</h4>     
    </div>
    -->

    <div id="cardcontainer" class="container" style="margin-top: 50px; background: none;">
      <div class="row row-cols-1 row-cols-lg-3 g-4">
        <div v-for="post in posts" :key="post.title" class="col">
          <div class="card h-100 blogcard">
            <img :src="post.image" class="card-img-top" alt="..." style="width: 100%; height: 100%;">
            <div class="card-body">
              <h5 class="card-title" style="font-family: 'Playfair Display', serif;"> {{post.title}}</h5>
              <span v-for="tag in post.tags" :key="tag.id" class="badge bg-info text-dark" style="margin-right: 5px;">{{tag}}</span>
              <!--<p class="card-text"><small class="text-muted">Posted on {{formatDate(post.date)}}</small></p>
              <p class="card-text"> {{post.summary.substring(0, 300)}}</p>-->
              <router-link :to="{ name: 'blog', params: {id: post.id}}" class="stretched-link"></router-link>
            </div>
            <div class="card-footer">
              <p class="card-text"><small class="text-muted">Posted on {{formatDate(post.date)}}</small></p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import axios from 'axios'
import {ref, onMounted} from 'vue'

const posts = ref([])
  
onMounted(() => {
    console.log("import axios from 'axios'")
    axios.get('https://coachspaceapp-1-u3686118.deta.app/posts').then( res => {
    //axios.get('posts.json').then( res => {
      console.log(res.data)
      posts.value = res.data
    })
  }
)

function formatDate(date) {
  var options = {year: 'numeric', month: 'long', day: 'numeric' }
  const date_obj = new Date(date)
  return date_obj.toLocaleDateString("en-US", options)
}
</script>

<style scoped>


  #headline {
    background-image: url('../assets/img/Hintergrund_5.jpg');
    
    background-size: cover;
    
    background-position:  50% 50%;
    
    background-repeat: no-repeat;
  }

  .card-img-top {
    width: 100%;
    height: auto !important;
    object-fit: cover;
  }

  .card-footer {
    background: none;
    border: none;
  }
</style>