<template>

  <div class="container" style="min-height: 0vh; margin-top: 100px; padding-top: 0px; margin-bottom: 50px; padding-bottom: 0px; padding-left: 0px; padding-right: 0px; background-color:#ced3d7;">
    <div class="row" style="margin-top: 0px; margin-bottom: 0px; margin-right: 0px;">
      
      <div class="d-none d-md-block col-md-4" style="margin-top: 0px; margin-bottom: 0px;">
        <img v-if="loadfinished" :src="booster[counter].image" style="width: 100%; height: 100%; object-fit: fill;">
      </div>

      <div class="col-12 col-md-8 position-relative" style="padding: 30px;" id="boosterhead">
      <!--<div class="col-sm-10 col-md-8 col-lg-7">-->
        <div v-if="loadfinished" style="padding-bottom: 30px; max-width:800px;">
          <h1 style="font-family: 'Roboto', sans-serif; font-weight: 700; margin-top: 50px;">{{ booster[counter].title }}</h1>
          <div v-html="booster[counter].body" class="boosterbody"></div>
        </div>
        <div class="position-absolute bottom-0 start-50 translate-middle-x" style="margin: 10px;">
          <button v-if="counter>0" class="btn btn-outline-secondary" style="margin: 10px;" @click="backward" type="button">Back</button>
          <button v-if="counter<len-1" class="btn btn-secondary" style="margin: 10px;" @click="forward" type="button">Next</button>
        </div>
      </div>

    </div>
    
    <div v-if="loadfinished" class="progress">
      <div class="progress-bar" role="progressbar" style="background-color: #feb2a7;" :style="'width: ' + (counter+1)/len*100+'%;'" :aria-valuenow="counter" aria-valuemin="0" :aria-valuemax="len"></div>
    </div>
    
  </div>

  <div class="container" style="padding-top: 50px;">
    <div class="row justify-content-center">
    

      <div class="col-sm-10 col-md-8 col-lg-7">

        <div class="disclaimer" >
          <h6>Medical disclaimer</h6>
          <p>Any content presented on this website is for informational purposes only. It does therefore not substitute the advice of a physician or other health care professional. If you may have a medical condition or are going to adopt a new health-related routine, always seek the advice of such a professional, and neither delay seeking nor disregard their advice because of anything you have read or heard on this website.</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import {ref, onMounted} from 'vue'
import { marked } from 'marked'

let loadfinished = ref(false)
let booster = ref([])
let counter = ref(0)
let len = ref(0)

onMounted(() => {
    
    axios.get('mindset.json').then( res => {
      console.log(res.data)
      res.data.mindsetboost.forEach(element => {
        booster.value.push(
          {
            title: element.title,
            image: element.image,
            body: marked.parse(element.body)
          }
        )
        len.value = booster.value.length
      })
      loadfinished.value = true
    })
  })

function forward () {
  if (counter.value < len.value-1) {
    counter.value++
    window.scrollTo(0,0)
  }
  
}

function backward () {
  if (counter.value > 0) {
    counter.value-- 
    window.scrollTo(0,0)
  }
}

</script>

<style scoped>
  .disclaimer {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 0.8em;
    color: grey;
    font-family: 'EB Garamond', serif;
  }
  
  .disclaimer > h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  }
</style>
