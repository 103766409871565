<template>
  <div>
    <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-light" style="padding-right: 10px;">
      <div class="container-fluid" style="padding-right: 0px;">
        <a class="navbar-brand" href="#" style="font-family: 'Playfair Display', serif;">Monika-Theresia Muetter</a>
        <button class="navbar-toggler"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse ms-auto" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" href="#about">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#blog">Blog</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div id="main" style="margin-top: 40px;">
      <router-view @emailupdated="emailupdate"></router-view>
    </div>
    <footer class="footer">
      <div class="container-fluid" style="min-height: 200px; background-color:#ced3d7;">
        <div class="container" style="padding-top: 50px; padding-bottom: 10px;">
          <div class="row" style="padding-bottom: 30px;">
            <div class="col-md-3" style="margin-top: 30px;">
              <h4>Connect</h4>
              <a href="https://instagram.com/monikamuetter?igshid=YmMyMTA2M2Y="><img style="height: 30px;" src="./assets/icons/icons8-instagram-48.png" /></a>
              <a href="https://www.linkedin.com/in/monika-theresia-m%C3%BCtter-577316153/"><img style="height: 34px;" src="./assets/icons/icons8-linkedin-48.png" /></a>
              <a href="mailto:monika-theresia@muetter.org"><img style="margin-left: 5px; height: 30px;" src="./assets/icons/icons8-mail-48.png" /></a>
            </div>
            <div class="col-md-3" style="margin-top: 30px;">
              <h4>Legal</h4>
              <ul class="list-unstyled">
                <li>
                  <a href="" data-bs-toggle="modal" data-bs-target="#imprintmodal">Imprint</a>
                </li>
                <li>
                  <a href="" data-bs-toggle="modal" data-bs-target="#gdprmodal">Data protection/GDPR</a>
                </li>
              </ul>
            </div>
            <div class="col-md-6" style="margin-top: 30px;">
              <h4>Newsletter</h4>
              <div style="max-width:500px;margin-top:0px;">
                <label for="signupfoot">Get new articles delivered directly to your inbox!</label>
                <div class="input-group mb-1">
                
                  <input id="signupfoot" v-model="email" type="text" class="form-control" placeholder="Your email address" aria-label="Your email." aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#newsletteroptin" type="button">Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12" style="padding-top: 10px;">
              <small>&copy; Monika Muetter 2022 &bull; Made by <a href="https://binaryinkstudio.com">Binary Ink Solutions</a> &bull; Icons by <a href="https://icons8.de>">icons8</a> </small>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <!-- Modal Imprint -->
    <div class="modal fade" id="imprintmodal" tabindex="-1" aria-labelledby="imprintmodal" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3> Contact </h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="text-align: left;">

            Monika-Theresia Mütter<br>
            {Address on request}<br>
            86825 Bad Wörishofen <br>
            Germany<br>

            monika-theresia@muetter.org
          </div>
        </div>
      </div>
    </div>

      <!-- Modal Newsletter -->
    <div class="modal fade" id="newsletteroptin" tabindex="-1" aria-labelledby="newsletteroptin" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3> Your consent is required </h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div style="color: black; margin-bottom: 20px;">
              
              <input class="form-check-input" type="checkbox" v-model="consentcheck" value="" id="flexCheckDefault">  
              I agree that my personal email is stored for the purpose of receiving the newsletter. I also agree to receive occasional marketing information. My consent can be revoked at any time.
            </div>

            <div>
              <button class="btn btn-primary" :class="{ 'disabled': !consentcheck }" @click="subscribe" data-bs-dismiss="modal" type="button" style="margin-right: 5px;">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal GDPR -->
    <div class="modal fade" id="gdprmodal" tabindex="-1" aria-labelledby="gdprmodal" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3 id="privacy-policy">Privacy Policy</h3>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body" style="text-align: left;">
            
            <p>This privacy policy will explain how we use the personal data we collect from you when you use our website.</p>
            <h4 id="what-data-do-we-collect-">What data do we collect?</h4>
            <ul>
              <li>Personal identification information: Email address.</li>
              <li>Data your browser sends to our server, like your IP address.</li>
            </ul>
            
            <h4 id="how-will-we-use-your-data-">How will we use your data?</h4>
            <p>We collect your data so that we can:</p>
            <ul>
              <li>Send you our newsletter (exempt from opt-in due to Art. 6 GDPR paragraph 1 (b))</li>
              <li>Determine the number of unique visitors to our site (exempt from opt-in due to Art. 6 GDPR paragraph 1 (f))</li>
            </ul>
            <p>The email address and your IP address are never paired, i.e. we do not track which email address belongs to which IP address, and we do not fingerprint your usage of our site.</p>
            <h4 id="how-do-we-store-your-data-">How do we store your data?</h4>
            <p>We securely store your data at our IT service provider.</p>
            <p>We keep the browser-related data for one month, after which it will be deleted. We will keep your email address for as long as you wish. You may unsubscribe from the newsletter whenever you wish, at which time we will also delete your email address.</p>
            
            <h4 id="what-are-your-data-protection-rights-">What are your data protection rights?</h4>
            <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
            <ul>
              <li><p>The right to access – You have the right to request us for copies of your personal data.</p>
              </li>
              <li><p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request us to complete the information you believe is incomplete.</p>
              </li>
              <li><p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
              </li>
              <li><p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
              </li>
              <li><p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
              </li>
              <li><p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
              </li>
            </ul>
            <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: monika-theresia@muetter.org</p>
            <h4 id="privacy-policies-of-other-websites">Privacy policies of other websites</h4>
            <p>Our website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>
            <h4 id="changes-to-our-privacy-policy">Changes to our privacy policy</h4>
            <p>We keep our privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 22 June 2022.</p>
            <h4 id="how-to-contact-us">How to contact us</h4>
            <p>If you have any questions about our privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</p>
            <p>Email us at: monika-theresia@muetter.org</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'

const token = ref('')
const ip = ref('')

const email = ref('')
const consentcheck = ref(false)

onMounted(() => {

  console.log(`Init.`)
  axios.get('https://coachspaceapp-1-u3686118.deta.app/tokenbroker')
    .then((res) => {
      token.value = res.data.token
      return axios.get('https://api.ipify.org/?format=json')})
      .then((res) => {
        ip.value = res.data.ip
        return axios.get('https://coachspaceapp-1-u3686118.deta.app/trackip', {params: {ip: ip.value, token: token.value}})})
        .then(() => {
          console.log('Finish.')
        })
})

function subscribe() {
  if (email.value.length > 0)
    console.log(consentcheck.value)
    if (consentcheck.value) {
      axios.post('https://coachspaceapp-1-u3686118.deta.app/newsletter-signup', email.value).then(() => {
        email.value = ''
        consentcheck.value = false
      })
    }
}

function emailupdate(e) {
  console.log('emailupdated', e)
  email.value = e
}
</script>


<style>
#app {
  font-family: 'Roboto', sans-serif;
  color: #000066;
}

h4{
  font-weight: 700;
}

nav {
  padding: 30px;
}

a {
  text-decoration: none;
  color: #000066;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
  margin-top: 0px
}

.textbody > blockquote {
    margin-top: 10px;
    /*text-align: justify;
    text-justify: inter-word;
    */
    font-family: 'Playfair Display', serif;
    margin-left: 15%;
    margin-right: 10%;
    font-size: 1.1em;
    color:gray;
  }

.textbody > p {
    margin-bottom: 30px;
  }

.textbody > ol {
    margin-bottom: 30px;
  }

.textbody > p > a {
    color:black;
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    text-decoration-color: #ced3d7;
  }

.textbody > p > a:hover {
    color: #0a58ca;
  }

.textbody > h3, h4, h5 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.textbody {
  font-family: 'Ibarra Real Nova', serif;
  font-family: 'EB Garamond', serif;
  color: black;
}

.boosterbody > div > img {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

.boosterbody > ul > li > img {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

.boosterbody {
  font-family: 'Ibarra Real Nova', serif;
  font-family: 'EB Garamond', serif;
  color: black;
  font-size: 1.3em;
  margin-top: 20px;
}

.boosterbody > h3, h4, h5 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
/*
 em {
    font-size: 0.8em;
  }
*/
@import'~bootstrap/dist/css/bootstrap.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&family=Ibarra+Real+Nova&family=Playfair+Display:wght@600&display=swap');
</style>
