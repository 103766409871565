import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BlogPostView from '../views/BlogPostView.vue'
import BlogView from '../views/BlogView.vue'
import MindsetBoost from '../views/MindsetBoost.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'home',
    component: HomeView
  },
  {
    path: '/blog',
    name: 'blogposts',
    component: BlogView
  },
  {
    path: '/blog/:id',
    name: 'blog',
    component: BlogPostView
  },
  {
    path: '/apps/boost',
    name: 'mindsetboost',
    component: MindsetBoost
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: function (to) {
    if (to.href == '#/about') {
      return {
        el: "#about",
        top: 40
      }    
    } else {
      return {
        el: '#main',
        top: 40
      }
    } 
  }
})

export default router
