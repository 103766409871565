<template>
  <div class="container-fluid" style="padding-top: 200px; padding-bottom: 200px; min-height: 200px; margin-top: 0px;" id="headline">
    <div class="row">
      
      <div class="col-0 col-md-2 col-lg-3"></div>

      <div class="col-12 col-md-8 col-lg-6">

        <h1 style="font-family: 'Playfair Display', serif;"> {{post.title}}</h1>

      </div>

      <div class="col-0 col-md-2 col-lg-3"></div>

    </div>
  </div>

  <div class="container-fluid" style="margin-top: 0px;">
    <div class="row justify-content-center">
    

      <div class="col-sm-10 col-md-8 col-lg-7">

        <div class="text-center" :class="{ inactive: loaderfinished }" style="margin-top: 50px;" id="loading">
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div class="textbody" v-html="post.body"></div>
        <div class="disclaimer" >
          <h6>Medical disclaimer</h6>
          <p>Any content presented on this website is for informational purposes only. It does therefore not substitute the advice of a physician or other health care professional. If you may have a medical condition or are going to adopt a new health-related routine, always seek the advice of such a professional, and neither delay seeking nor disregard their advice because of anything you have read or heard on this website.</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import {ref, onMounted} from 'vue'
import { useRoute } from 'vue-router'
import { marked } from 'marked'

const post = ref({})
const route = useRoute()

const loaderfinished = ref(false)

onMounted(() => {
    console.log(route.params.id)
    axios.get('https://coachspaceapp-1-u3686118.deta.app/posts').then( res => {
      res.data.forEach(element => {
        if (element.id == route.params.id) {          
          post.value = element
          post.value.body = marked.parse(post.value.body)
          loaderfinished.value = true
        }
      });
    })
  })
</script>

<style scoped>
  .textbody {
    margin-top: 50px;
    /*text-align: justify;
    text-justify: inter-word;
    */
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.3em;
  }

  .disclaimer {
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 0.8em;
    color: grey;
    font-family: 'EB Garamond', serif;
  }
  
  .disclaimer > h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  }

  .textbody > blockquote {
    margin-top: 500px;
    /*text-align: justify;
    text-justify: inter-word;
    */
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.3em;
  }

  .inactive {
    display: none;
  }


  #headline {
    background-image: url('../assets/img/Hintergrund_5.jpg');
    
    background-size: cover;
    
    background-position:  50% 50%;
    
    background-repeat: no-repeat;
  }
</style>